import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { FlexLayoutModule } from '@angular/flex-layout';

import { AppMaterialModule } from './app-material/app-material.module';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { NavComponent } from './nav/nav.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { ProfileComponent } from './profile/profile.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FirebaseInterceptor } from 'src/app/http-interceptors/firebase-interceptor';
import { ClassificationComponent } from './classification/classification.component';
import { RegionComponent } from './classification/region/region.component';
import { FilmstripComponent } from './classification/filmstrip/filmstrip.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeviceListComponent } from './device/device-list/device-list.component';
import { LabelDialogComponent } from './classification/label-dialog/label-dialog.component';
import { LabelSelectorComponent } from './classification/label-selector/label-selector.component';
import { DeviceSettingsComponent } from './device/device-settings/device-settings.component';
import { LoginComponent } from './admin/login/login.component';
import { RegisterComponent } from './admin/register/register.component';
import { ForgotPasswordComponent } from './admin/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './admin/verify-email/verify-email.component';
import { SystemComponent } from './device/settings/system/system.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { AddDeviceDialogComponent } from './device/add-device-dialog/add-device-dialog.component';
import { AutofocusDirective } from './utils/mat-input-autofocus.directive';
import { FieldAgentComponent } from './device/settings/field-agent/field-agent.component';
import { CameraComponent } from './device/settings/camera/camera.component';
import { LocatorComponent } from './device/settings/locator/locator.component';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { FooterComponent } from './footer/footer.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { SharedModule } from './shared/shared.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule({
  declarations: [
    AppComponent,
    SplashScreenComponent,
    NavComponent,
    NavBarComponent,
    ProfileComponent,
    ClassificationComponent,
    RegionComponent,
    FilmstripComponent,
    DeviceListComponent,
    LabelDialogComponent,
    LabelSelectorComponent,
    DeviceSettingsComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    SystemComponent,
    AddDeviceDialogComponent,
    AutofocusDirective,
    FieldAgentComponent,
    CameraComponent,
    LocatorComponent,
    FooterComponent,
    PrivacyComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppMaterialModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxMapboxGLModule.withConfig({
      accessToken:
        'pk.eyJ1Ijoibm90c29zYXBpZW4iLCJhIjoiY2t0NWVkeHVuMDE2OTJ3cGg5Z2ZqNGZ1biJ9.yTOyvUlRHPDj64pvU0NUvg', // Optional, can also be set per map (accessToken input of mgl-map)
      //geocoderAccessToken: 'TOKEN', // Optional, specify if different from the map access token, can also be set per mgl-geocoder (accessToken input of mgl-geocoder)
    }),
  ],
  exports: [FooterComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FirebaseInterceptor,
      multi: true,
    },
    [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
    provideCharts(withDefaultRegisterables()),
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
