/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { LabelList, LabelRegion, Spaia_Label } from '../models/label';
import { Region } from '../models/region';

@Injectable({
  providedIn: 'root',
})
export class LabelService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient,
    firebase: AngularFireAuth
  ) {
    super(config, http, firebase);
  }

  /**
   * Path part for operation getDevices
   */
  static readonly GetLabelPath = '/labels';

  /**
   * returns all registered labels
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDevices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabels$Response(params?: {
    /**
     * where to start
     */
    offset?: number;

    /**
     * max number of records to return
     */
    limit?: number;
    search?: string;
  }): Observable<StrictHttpResponse<LabelList>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      LabelService.GetLabelPath,
      'get'
    );
    if (params) {
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
      rb.query('search', params.search, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LabelList>;
        })
      );
  }

  /**
   * returns all registered labels
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDevices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLabels(params?: {
    /**
     * where to start
     */
    offeset?: number;
    /**
     * max number of records to return
     */
    limit?: number;
    /**
     * part of the name
     */
    search?: string;
  }): Observable<LabelList> {
    return this.getLabels$Response(params).pipe(
      map((r: StrictHttpResponse<LabelList>) => {
        return r.body;
      })
    );
  }
  /**
   * Path part for operation updateEvent
   */
  static readonly CreateLabelPath = '/labels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `CreateEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createLabel$Response(params: {
    body: Spaia_Label;
  }): Observable<StrictHttpResponse<Spaia_Label>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      LabelService.CreateLabelPath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Spaia_Label>;
        })
      );
  }
  createLabel(params: { body: Spaia_Label }): Observable<Spaia_Label> {
    return this.createLabel$Response(params).pipe(
      map((r: StrictHttpResponse<Spaia_Label>) => r.body as Spaia_Label)
    );
  }

  static readonly LinkLabelPath = '/label/region/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `CreateEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkLabel$Response(params: {
    body: LabelRegion;
  }): Observable<StrictHttpResponse<Region>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      LabelService.LinkLabelPath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Region>;
        })
      );
  }
  linkLabel(params: { body: LabelRegion }): Observable<Region> {
    return this.linkLabel$Response(params).pipe(
      map((r: StrictHttpResponse<Region>) => r.body as Region)
    );
  }
  deLinkLabel$Response(params: {
    body: LabelRegion;
  }): Observable<StrictHttpResponse<Region>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      LabelService.LinkLabelPath,
      'delete'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Region>;
        })
      );
  }
  deLinkLabel(params: { body: LabelRegion }): Observable<Region> {
    return this.deLinkLabel$Response(params).pipe(
      map((r: StrictHttpResponse<Region>) => r.body as Region)
    );
  }
}
