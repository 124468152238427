import { Component, OnInit } from '@angular/core';

import { MeService } from '../api/services';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  public photoURL = '';
  public token = '';
  constructor(private me: MeService) {}

  async ngOnInit(): Promise<void> {
    (await this.me.getMe()).subscribe((data) => {
      console.log(data);
    });
  }
}
