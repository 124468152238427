// src/app/shared/shared.module.ts
import { NgModule } from '@angular/core';
import { SidebarComponent } from '../classification/sidebar/sidebar.component';
import { AreaOfInterestComponent } from '../device/area-of-interest/area-of-interest.component';

@NgModule({
  imports: [SidebarComponent, AreaOfInterestComponent],
  exports: [SidebarComponent, AreaOfInterestComponent],
})
export class SharedModule {}
