<mat-card>
  <mat-card-title>Add a device</mat-card-title>
  <mat-card-content>
    <form fxFlex fxLayout="column" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill">
        <mat-label>Device Name</mat-label>
        <input
          matInput
          placeholder="Device Name"
          appearance="fill"
          required
          [formControl]="name"
        />
      </mat-form-field>

      <div
        class="button-row"
        fxLayout="row"
        fxLayoutAlign="end center"
        fxFlex
        fxLayoutGap="10px"
      >
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="!name.valid"
        >
          <span>Add existing device</span>
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="createDevice()"
          [disabled]="!name.valid"
          type="button"
        >
          <span>Create a new one</span>
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
