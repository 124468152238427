/* tslint:disable */
/* eslint-disable */
import { Spaia_Label } from './label';
export interface Region {
  eventId?: number;
  h: number;
  id?: number;
  w: number;
  x: number;
  y: number;
  labels: Array<Spaia_Label>;
  createdAt?: string;
  updatedAt?: string;
}
export const blankRegion = { x: 0, y: 0, w: 0, h: 0, labels: [] };
