<mat-grid-list [cols]="cols" rowHeight="1:1" gutterSize="25" class="m-5">
  <mat-grid-tile *ngFor="let device of devices">
    <mat-card *ngIf="device.id" class="flex w-full h-full">
      <mat-card-title-group class="px-3 pt-3">
        <mat-card-title>{{ device.name }}</mat-card-title>
        <mat-card-subtitle
          *ngIf="device && device.deviceType && device.deviceType.name"
          >{{ device.deviceType.name }}</mat-card-subtitle
        >
        <mat-menu #menu="matMenu">
          <button mat-menu-item><mat-icon>remove</mat-icon>Remove</button>
          <button mat-menu-item disabled>
            <mat-icon>share</mat-icon>
            <span>Share</span>
          </button>
        </mat-menu>
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          style="margin-top: -10px"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-card-title-group>
      <img
        *ngIf="device && device.deviceType && device.deviceType.id"
        mat-card-image
        class="flex h-2/3"
        src="../../assets/images/{{ device.deviceType.id }}.png"
      />
      <mat-card-footer class="px-3">
        <span *ngIf="device && device.lastSeen" class="text-xs">
          last seen on: {{ device.lastSeen | date : "short" }}
        </span>
      </mat-card-footer>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile class="flex">
    <mat-card
      (click)="addDevice()"
      class="flex flex-col item w-full h-full items-center justify-center"
    >
      <mat-icon
        mat-card-image
        aria-hidden="false"
        aria-label="Add a device"
        class="addIcon"
        >add</mat-icon
      >
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>
