import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  constructor(public auth: AuthService, private router: Router) {}
  login() {
    this.auth.signInWithGoogle();
  }
  logout() {
    this.auth.signOut();
    this.router.navigateByUrl('/');
  }

  ngOnInit(): void {}
}
