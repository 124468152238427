<div
  (keyup.arrowright)="nextEvent()"
  class="flex flex-col flex-grow flex-shrink w-full h-full"
>
  <mat-toolbar class="flex justify-between items-center">
    <div
      class="flex"
      *ngIf="events[activeEvent]"
      class="text-[16px] font-light"
    >
      <span *ngIf="events[activeEvent].time">
        {{ events[activeEvent].time | date : "medium" }}&nbsp;
      </span>
      &nbsp;
      <span *ngFor="let data of events[activeEvent].sensorData">
        <span *ngIf="data.sensorId == 1"> {{ data.value }}&deg;</span>
      </span>
      &nbsp;
      <span *ngIf="eventService.count">
        ({{
          activeEvent + 1 + (eventFilters.offset ? eventFilters.offset : 0)
        }}
        of {{ eventService.count }})
      </span>
    </div>
    <button mat-icon-button *ngIf="eventService.count" (click)="getEvents()">
      <mat-icon>refresh</mat-icon>
    </button>
  </mat-toolbar>
  <div class="flex justify-between flex-grow items-center w-full">
    <div class="flex flex-grow justify-center items-center w-8/12">
      <div class="flex flex-col justify-center items-center w-full h-full">
        <div
          #holder
          class="img-holder"
          *ngIf="
            events[activeEvent] &&
              events[activeEvent].id &&
              events[activeEvent].media &&
              events[activeEvent].media[0] &&
              events[activeEvent].media[0].fileId;
            else noImage
          "
        >
          <img
            #imgElementV
            id="imgElementV"
            class="w-auto h-auto max-w-full max-h-full mx-auto"
            src="{{
              events[activeEvent].media[0].fileId.indexOf('http') > -1
                ? events[activeEvent].media[0].fileId
                : 'https://imagedelivery.net/fo3ozqx41r8Hdje7X3DFaQ/' +
                  events[activeEvent].media[0].fileId +
                  '/w=2560'
            }}"
            alt=""
            (mousedown)="startRegion($event)"
            (mousemove)="drawRegion($event)"
            (mouseup)="addNewRegion($event)"
            (mouseleave)="addNewRegion($event)"
          />
          <!-- <img
            [hidden]="!activeImage"
            #imgElementX
            id="imgElementX"
            fxFlexFill
            src="{{
              events[activeEvent].media[1].fileId.indexOf('http') > -1
                ? events[activeEvent].media[1].fileId
                : 'https://imagedelivery.net/fo3ozqx41r8Hdje7X3DFaQ/' +
                  events[activeEvent].media[1].fileId +
                  '/public'
            }}"
            alt=""
            (mousedown)="startRegion($event)"
            (mousemove)="drawRegion($event)"
            (mouseup)="addNewRegion($event)"
            (mouseleave)="addNewRegion($event)"
          /> -->
          <img
            #imgElement
            id="imgElement"
            src="{{
              events[activeEvent].media[0].fileId.indexOf('http') > -1
                ? events[activeEvent].media[0].fileId
                : 'https://imagedelivery.net/fo3ozqx41r8Hdje7X3DFaQ/' +
                  events[activeEvent].media[0].fileId +
                  '/medium'
            }}"
            alt=""
            class="active-image-b"
          />
          <!-- <img
            #imgElementB
            id="imgElementB"
            src="{{
              events[activeEvent + 1].media[1].fileId.indexOf('http') > -1
                ? events[activeEvent + 1].media[1].fileId
                : 'https://imagedelivery.net/fo3ozqx41r8Hdje7X3DFaQ/' +
                  events[activeEvent + 1].media[1].fileId +
                  '/public'
            }}"
            class="active-image-b"
            (load)="guessRegions()"
          /> -->

          <app-region
            class="region"
            *ngFor="let region of events[activeEvent].regions; let i = index"
            [region]="region"
            (regionChange)="setActiveRegion($event)"
            [isActive]="activeRegion.id == region.id"
            [ngStyle]="{
              'left.px': region.x / scale,
              'top.px': region.y / scale,
              'width.px': region.w / scale,
              'height.px': region.h / scale
            }"
            cdkDrag
            cdkDragBoundary=".img-holder"
            cdkDragData="{{ i }}"
            cdkDragPreviewContainer="parent"
            [cdkDragFreeDragPosition]="dragPosition"
            style="z-index: 9999"
            (deleteRegion)="deleteRegion($event)"
            (dblclick)="openDialog(region)"
            [scale]="scale"
          >
          </app-region>
        </div>
        <ng-template #noImage>
          <mat-icon>
            <span class="material-icons"> broken_image </span>
          </mat-icon>
        </ng-template>
        <div
          *ngIf="events[activeEvent] && events[activeEvent].id"
          class="flex items-center justify-between mt-2 w-full px-10"
        >
          <div class="flex w-full items-center justify-center space-x-2">
            <button
              mat-raised-button
              (click)="
                selectedEvents.length > 1
                  ? deleteEvents()
                  : deleteEvent(activeEvent)
              "
            >
              Delete
              {{
                selectedEvents.length > 1 ? selectedEvents.length + " events" : ""
              }}
            </button>
            <!-- <button mat-raised-button (click)="toggleImage()" class="mx2">
              toggle
            </button> -->
            <button
              mat-raised-button
              (click)="verifyEvent(activeEvent)"
              class="mx2"
            >
              Save
            </button>
            <button mat-raised-button (click)="nextEvent()">Skip</button>
          </div>
         
          
          <button mat-icon-button (click)="downloadImage(events[activeEvent])">
            <mat-icon>download</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="sidebar-holder justify-stretch h-full w-4/12">
      <app-sidebar
        [events]="events"
        (filterChange)="getEvents($event)"
        (downloadData)="downloadData()"
        [activeEvent]="events[activeEvent]"
        [activeRegion]="activeRegion"
        class="flex"
      ></app-sidebar>
    </div>
  </div>
  <div class="filmstrip flex min-h-32">
    <div class="flex flex-col overflow-hidden items-center justify-center w-12">
      <button mat-icon-button (click)="previousEvents()">
        <mat-icon>arrow_backward_ios</mat-icon>
      </button>
    </div>

    <div class="flex overflow-hidden w-full flex-grow" #filmstrip>
      <app-filmstrip
        [events]="events"
        [(activeEvent)]="activeEvent"
        [(selectedEvents)]="selectedEvents"
        class="flex overflow-hidden"
      ></app-filmstrip>
    </div>
    <div class="flex flex-col items-center justify-center overflow-hidden w-12">
      <button mat-icon-button (click)="nextEvents()" [disabled]="noMoreEvents">
        <mat-icon>arrow_forward_ios</mat-icon>
      </button>
    </div>
  </div>
</div>
