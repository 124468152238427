/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Spaia_Event } from '../models/event';
import { Events } from '../models/events';
import { EventFilters, EventsResponse } from '../models/events-response';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Region } from '../models/region';
import { Spaia_Label } from '../models/label';

@Injectable({
  providedIn: 'root',
})
export class EventsService extends BaseService {
  offset = 0;
  count = 0;
  constructor(
    config: ApiConfiguration,
    http: HttpClient,
    firebase: AngularFireAuth
  ) {
    super(config, http, firebase);
  }

  /**
   * Path part for operation getEvents
   */
  static readonly GetEventsPath = '/events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEvents$Response(
    params: EventFilters
  ): Observable<StrictHttpResponse<EventsResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.GetEventsPath,
      'get'
    );
    if (params) {
      rb.query('deviceId', params.deviceIds, {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
      rb.query('before', params.before, {});
      rb.query('after', params.after, {});
      rb.query('hasRegions', params.hasRegions, {});
      rb.query('hasNoRegions', params.hasNoRegions, {});
      rb.query('hasLabels', params.hasLabels, {});
      rb.query('ascending', params.ascending, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          this.count = r.body.count;
          return r as StrictHttpResponse<EventsResponse>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEvents(params: EventFilters): Observable<EventsResponse> {
    console.log('params', params);
    return this.getEvents$Response(params).pipe(
      map((r: StrictHttpResponse<EventsResponse>) => r.body as EventsResponse)
    );
  }

  /**
   * Path part for operation setEvents
   */
  static readonly SetEventsPath = '/events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setEvents()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  async setEvents$Response(params: {
    body: Events;
  }): Promise<Observable<StrictHttpResponse<void>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.SetEventsPath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        await rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setEvents$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  async setEvents(params: { body: Events }): Promise<Observable<void>> {
    return (await this.setEvents$Response(params)).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateEvent
   */
  static readonly CreateRegionPath = '/region';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `CreateEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRegion$Response(params: {
    body: Region;
  }): Observable<StrictHttpResponse<Region>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.CreateRegionPath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Region>;
        })
      );
  }
  createRegion(params: { body: Region }): Observable<Region> {
    return this.createRegion$Response(params).pipe(
      map((r: StrictHttpResponse<Region>) => r.body as Region)
    );
  }
  /**
   * Path part for operation updateEvent
   */
  static readonly UpdateEventPath = '/event/{eventId}';
  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEvent$Response(params: {
    eventId: number;
    body: Spaia_Event;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.UpdateEventPath,
      'put'
    );
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  async updateEvent(params: {
    eventId: number;
    body: Spaia_Event;
  }): Promise<Observable<void>> {
    return (await this.updateEvent$Response(params)).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  verifyEvent$Response(eventId: number): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.UpdateEventPath + '/verify',
      'put'
    );
    if (eventId) {
      rb.path('eventId', eventId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  verifyEvent(eventId: number): Observable<void> {
    return this.verifyEvent$Response(eventId).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteEvent
   */
  static readonly DeleteEventPath = '/event/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteEvent$Response(
    eventId: number
  ): Observable<StrictHttpResponse<{ message?: string }>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsService.DeleteEventPath,
      'delete'
    );
    if (eventId) {
      rb.path('eventId', eventId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ message?: string }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteEvent(eventId: number): Observable<{ message?: string }> {
    return this.deleteEvent$Response(eventId).pipe(
      map(
        (r: StrictHttpResponse<{ message?: string }>) =>
          r.body as { message?: string }
      )
    );
  }
}
