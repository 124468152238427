<mat-list role="list" class="menu-list flex flex-col" style="min-width: 205px">
  <mat-list-item
    role="listitem"
    routerLink="/"
    style="height: 150px; margin-bottom: 3rem"
  >
    <img
      src="https://imagedelivery.net/fo3ozqx41r8Hdje7X3DFaQ/0f869c00-9275-4336-a267-f312cad86a00/public?width=300"
      alt="SPAIA - the society of people against the insect Apocalypse"
      width="150"
      sizes="(max-width: 500px) 100vw, (max-width: 1000px) 50vw, 33vw"
      style="display: block; width: 150px; margin: auto"
    />
  </mat-list-item>
  <div class="flex flex-col w-full space-y-10 h-full">
    <mat-list-item
      *ngIf="!(auth && auth.userData | async)"
      class="justify-end mb-3"
    >
      <button mat-button color="primary" routerLink="/register">
        <span class="font-light">Register</span>
      </button>
    </mat-list-item>
    <mat-list-item
      *ngIf="!(auth && auth.userData | async)"
      class="justify-end mb-3"
    >
      <button mat-button routerLink="/login">Login</button>
    </mat-list-item>
    <div *ngIf="auth && auth.userData | async">
      <mat-list-item role="listitem" class="justify-end mb-3"
        ><button mat-button routerLink="/devices" class="text-white text-xl">
          <span class="font-light">Manage Devices</span>
        </button>
      </mat-list-item>
      <mat-list-item role="listitem" class="justify-end mb-3">
        <button mat-button routerLink="/classification">
          <span class="font-light">Label Images</span>
        </button>
      </mat-list-item>
      <mat-list-item role="listitem" class="justify-end mb-3">
        <button mat-button routerLink="/data/explorer">
          <span class="font-light">Explore Data</span>
        </button>
      </mat-list-item>
      <mat-list-item role="listitem" class="justify-end mb-3"
        ><a
          mat-button
          href="https://docs.google.com/forms/d/e/1FAIpQLSd89iZCj_zFTpmWkSIYD83kZJTQqfEzRaLPttNEbO9PJuO1Fg/viewform"
          target="_BLANK"
          ><span class="font-light">Provide Feedback</span></a
        >
      </mat-list-item>
      <mat-list-item role="listitem" class="justify-end mb-3"
        ><a
          mat-button
          target="_BLANK"
          href="https://docs.google.com/forms/d/e/1FAIpQLSfafWkMJDsLFfND83OljkG1JORh3Tr1v42xIP8zPbZ06DNGjA/viewform"
        >
          <span class="font-light">Report a Glitch</span>
        </a>
      </mat-list-item>
    </div>
  </div>
</mat-list>
