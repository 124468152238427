<div
  class="flex flex-col flex-grow flex-shrink w-full h-full items-center justify-center"
>
  <div
    class="flex flex-grow flex-shrink w-full h-full items-center justify-center"
  >
    <mat-card class="w-8/12">
      <mat-card-title class="p-4">
        <span *ngIf="isSignup">Signup</span>
        <span *ngIf="!isSignup">Login</span>
      </mat-card-title>
      <mat-card-content class="flex flex-col">
        <form
          [formGroup]="loginForm"
          class="flex flex-col"
          (ngSubmit)="onSubmit()"
        >
          <!-- <mat-form-field appearance="fill" *ngIf="isSignup">
            <mat-label>Name</mat-label>
            <input
              matInput
              placeholder="name"
              appearance="fill"
              formControlName="name"
            />
          </mat-form-field> -->
          <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>
            <input
              matInput
              placeholder="email"
              appearance="fill"
              formControlName="email"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Password</mat-label>
            <input
              matInput
              type="password"
              placeholder="password"
              appearance="fill"
              formControlName="password"
            />
            <mat-hint align="end">
              {{ loginForm.value.password.length }} / 6
            </mat-hint>
          </mat-form-field>
          <div
            class="button-row"
            fxLayout="row"
            fxLayoutAlign="end center"
            fxFlex
          >
            <button
              mat-button
              color="warn"
              *ngIf="!isSignup"
              (click)="resetPassword()"
            >
              forgot Password
            </button>

            <button
              mat-raised-button
              color="primary"
              type="submit"
              [disabled]="!loginForm.valid"
            >
              <span *ngIf="!isSignup"> login </span>
              <span *ngIf="isSignup"> Sign up </span>
            </button>
          </div>
        </form>
        <div class="button-row" fxLayout="row" fxLayoutAlign="center">
          <a href="register" mat-button *ngIf="!isSignup"> New here? </a>
          <a
            href="login"
            mat-button
            (click)="loginWithGoogle()"
            *ngIf="isSignup"
          >
            Already signed up?
          </a>

          <button mat-button (click)="loginWithGoogle()" class="flex">
            <img
              src="../../assets/images/g-logo.png"
              alt="google logo"
              class="google-logo"
            />
            Login with Google
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
