<form
  class="flex w-full h-full flex-col"
  [formGroup]="formGroup"
  ngNativeValidate
>
  <mat-list class="flex w-full h-full">
    <mat-list-item class="mb2" class="flex w-full h-full">
      <mat-form-field class="flex w-full h-full">
        <mat-label>Time to sleep</mat-label>
        <input
          matInput
          #sleepTime
          formControlName="sleepTime"
          (change)="saveSetting({ name: 'sleepTime', value: sleepTime.value })"
          type="number§"
        />
      </mat-form-field>
    </mat-list-item>
    <mat-list-item class="mb2" class="flex w-full h-full">
      <mat-form-field class="flex w-full h-full">
        <mat-label>Time to stay awake</mat-label>
        <input
          matInput
          #wakeTime
          formControlName="wakeTime"
          (change)="saveSetting({ name: 'wakeTime', value: wakeTime.value })"
        />
      </mat-form-field>
    </mat-list-item>
    <mat-list-item class="mb2 flex w-full h-full">
      <mat-form-field class="flex w-full h-full">
        <mat-label>IR sensitivity</mat-label>
        <input
          matInput
          #sensitivity
          type="number"
          min="1"
          max="100"
          formControlName="sensitivity"
          (change)="
            saveSetting({ name: 'sensitivity', value: sensitivity.value })
          "
        />
      </mat-form-field>
    </mat-list-item>
    <mat-list-item class="flex w-full h-full" style="min-height: 500px">
      <app-locator class="flex w-full h-full"></app-locator>
    </mat-list-item>
  </mat-list>
</form>
