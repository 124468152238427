import { CdkDragMove } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Region } from 'src/app/api/models';
import { RegionService } from 'src/app/api/services/region.service';

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.scss'],
})
export class RegionComponent implements OnInit {
  @Input() region: Region = { x: 0, y: 0, h: 0, w: 0, labels: [] };
  @Input() scale: number = 1;
  @Output() regionChange = new EventEmitter<Region>();
  @Output() deleteRegion = new EventEmitter<number>();
  @Input() isActive: boolean = false;
  dragPosition = { x: 0, y: 0 };
  previousDragValue = { x: 0, y: 0 };

  constructor(private regionService: RegionService) {}

  ngOnInit(): void {}
  setActive(): void {
    this.regionChange.emit(this.region);
  }
  dragRight(ev: CdkDragMove): void {
    if (!this.region) return;
    this.region.w += Math.round(
      ev.source.getFreeDragPosition().x * this.scale -
        this.previousDragValue.x * this.scale
    );
    this.previousDragValue.x = ev.source.getFreeDragPosition().x;
    this.dragPosition = { x: 0, y: 0 };
  }

  dragDown(ev: CdkDragMove): void {
    this.region.h += Math.round(
      ev.source.getFreeDragPosition().y * this.scale -
        this.previousDragValue.y * this.scale
    );
    this.previousDragValue.y = ev.source.getFreeDragPosition().y;
    this.dragPosition = { x: 0, y: 0 };
  }
  releaseRegion(region: Region): void {
    this.dragPosition = { x: 0, y: 0 };
    this.previousDragValue = { x: 0, y: 0 };
    this.regionService
      .updateRegion({
        regionId: region.id as number,
        body: region,
      })
      .subscribe(() => {});
  }
  delete(): void {
    if (this.region.id) {
      this.regionService.deleteRegion(this.region.id).subscribe(() => {
        this.deleteRegion.emit(this.region.id);
      });
    } else {
      this.deleteRegion.emit(this.region.id);
    }
  }
}
