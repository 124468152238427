import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public isSignup = true;
  loginForm = this.fb.group({
    name: [''],
    email: ['', Validators.required],
    password: ['', [Validators.minLength(6), Validators.required]],
  });
  onSubmit = () => {
    this.isSignup ? this.signup() : this.login();
  };
  loginWithGoogle = () => {
    this.auth
      .signInWithGoogle()
      .then(() => {
        this._snackBar.open('Success! You are logged in.', 'ok', {
          duration: 1500,
        });
        this.router.navigateByUrl('/');
      })
      .catch((err: any) => {
        this._snackBar.open('error: ' + err.message, 'ok', { duration: 4000 });
      });
    this.router.navigateByUrl('/');
  };
  login = () => {
    this.auth
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .then(() => {
        this._snackBar.open('welcome!', 'ok', { duration: 2000 });
      })
      .catch((err) => {
        console.log('err', err);
        this._snackBar.open(err, 'ok', { duration: 4000 });
      });
  };
  signup = () => {
    this.auth
      .signUp(this.loginForm.value.email, this.loginForm.value.password)
      .then(() => {
        this._snackBar.open('Success! You are logged in.', 'ok', {
          duration: 1500,
        });
        this.router.navigateByUrl('/');
      })
      .catch((err: any) => {
        this._snackBar.open('error: ' + err.message, 'ok', { duration: 4000 });
      });
  };
  resetPassword = () => {
    this.auth.resetPassword(this.loginForm.value.email).then((res) => {
      console.log(res);
    });
  };
  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isSignup = this.router.url.includes('register');
  }
}
