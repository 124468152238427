import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Region } from 'src/app/api/models';
import { Spaia_Label } from 'src/app/api/models/label';
import { LabelService } from 'src/app/api/services/label.service';
import { ClassificationComponent } from 'src/app/classification/classification.component';

@Component({
  selector: 'app-label-dialog',
  templateUrl: './label-dialog.component.html',
  styleUrls: ['./label-dialog.component.scss'],
})
export class LabelDialogComponent implements OnInit {
  constructor(
    private labelService: LabelService,
    public dialogRef: MatDialogRef<ClassificationComponent>,
    @Inject(MAT_DIALOG_DATA) public region: Region
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }
  linkLabel(label: Spaia_Label): void {
    this.labelService
      .linkLabel({
        body: {
          regionId: this.region.id as number,
          labelId: label.id as number,
        },
      })
      .subscribe((data) => this.region.labels.push(label));
  }
  delinkLabel(label: Spaia_Label): void {
    this.labelService
      .deLinkLabel({
        body: {
          labelId: label.id as number,
          regionId: this.region.id as number,
        },
      })
      .subscribe(() => {
        this.region.labels = this.region.labels.filter(
          (data) => data.id != label.id
        );
      });
  }
  ngOnInit(): void {}
}
