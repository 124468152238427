<div *ngIf="device" class="flex flex-grow w-full h-full flex-col">
  <mat-toolbar class="flex w-full">
    <h4>{{ device.name }}</h4>
  </mat-toolbar>
  <div class="flex w-full">
    <div
      style="height: calc(100vh - 65px)"
      *ngIf="deviceConnected ? true : false"
    >
      <mat-card class="flex justify-center items-center w-full">
        <mat-card-content
          *ngIf="device && device.ip && deviceConnected"
          class="flex flex-col items-stretch justify-center w-full"
        >
          <img class="stream" src="http://{{ device.ip }}/video_feed" alt="" />
        </mat-card-content>
        <mat-card-content
          class="flex flex-col items-center justify-stretch w-full"
          *ngIf="deviceConnected == false"
        >
          <div style="width: 50%">
            <h2>Device not online</h2>
            <p>
              Please check that your device is powered on and connected to WIFI.
            </p>
            <a
              mat-button
              href="http://{{ device.ip }}/video_feed"
              target="_BLANK"
              >Connect directly</a
            >
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="flex w-full">
      <mat-tab-group mat-align-tabs="center" [selectedIndex]="2" class="w-full">
        <mat-tab label="Status"></mat-tab>
        <mat-tab label="Settings">
          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0">
              <mat-expansion-panel-header>
                <mat-panel-title>Camera Settings</mat-panel-title>
              </mat-expansion-panel-header>
              <mat-list>
                <mat-list-item>
                  Exposure Bias
                  <button
                    mat-icon-button
                    aria-label="decrease exposureBias"
                    (click)="adjustExposureBias(-1)"
                  >
                    <mat-icon>remove</mat-icon>
                  </button>
                  {{ cameraSettings.autoExposureBias }}
                  <button
                    mat-icon-button
                    aria-label="increase exposureBias"
                    (click)="adjustExposureBias(1)"
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                </mat-list-item>
                <mat-list-item>
                  Brightness
                  <button
                    mat-icon-button
                    aria-label="decrease exposureBias"
                    (click)="adjustBrightness(-1)"
                  >
                    <mat-icon>remove</mat-icon>
                  </button>
                  {{ cameraSettings.brightness }}
                  <button
                    mat-icon-button
                    aria-label="increase exposureBias"
                    (click)="adjustBrightness(1)"
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                </mat-list-item>
                <mat-list-item>
                  <div mat-line>
                    <div
                      matTooltip="If this setting is enabled the frame rate will automatically slow down in low light conditions"
                    >
                      Dynamic Frame rate
                    </div>
                  </div>
                  <div mat-line>
                    <mat-slide-toggle
                      class="example-margin"
                      [checked]="cameraSettings.dynamicFrameRate"
                    ></mat-slide-toggle>
                  </div>
                </mat-list-item>
              </mat-list>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0" expanded="true">
              <mat-expansion-panel-header>
                <mat-panel-title>System Settings</mat-panel-title>
              </mat-expansion-panel-header>
              <app-field-agent></app-field-agent>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-tab>
        <mat-tab label="Areas of Interest">
          <app-area-of-interest></app-area-of-interest>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
