<mat-toolbar
  class="main-menu"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <div>
    <p class="name-display" *ngIf="auth && auth.userData | async">
      {{ (auth.userData | async)?.email }}
    </p>
  </div>
  <div>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngIf="auth && auth.userData | async">
        <mat-icon>person</mat-icon> My Account
      </button>
      <button
        mat-menu-item
        *ngIf="auth && auth.userData | async"
        (click)="logout()"
      >
        <mat-icon>person</mat-icon> Log Out
      </button>
      <a mat-menu-item href="/login" *ngIf="!(auth && auth.userData | async)">
        <mat-icon>person_add</mat-icon>
        <span>Sign In</span>
      </a>
      <a
        mat-menu-item
        href="/register"
        *ngIf="!(auth && auth.userData | async)"
      >
        <mat-icon>person_add</mat-icon>
        <span>Sign Up</span>
      </a>
    </mat-menu>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</mat-toolbar>
