import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { LabelList, Spaia_Label } from 'src/app/api/models/label';
import { LabelService } from 'src/app/api/services/label.service';

@Component({
  selector: 'app-label-selector',
  templateUrl: './label-selector.component.html',
  styleUrls: ['./label-selector.component.scss'],
})
export class LabelSelectorComponent implements OnInit {
  @Input() labels: Array<Spaia_Label> = [];
  @Output() linkLabel = new EventEmitter<Spaia_Label>();
  constructor(private labelService: LabelService) {}

  ngOnInit(): void {}

  labelControl = new UntypedFormControl();
  filteredLabels = this.labelControl.valueChanges.pipe(
    startWith(''),
    debounceTime(400),
    distinctUntilChanged(),
    switchMap((val) => {
      return this.filter(val || '');
    })
  );
  filter(val: string | Spaia_Label): Observable<Spaia_Label[]> {
    let q: string;
    if (typeof val !== 'string') {
      q = val.name ? val.name : '';
    } else {
      q = val;
    }
    return this.labelService.getLabels(q ? { search: q } : {}).pipe(
      map((response: LabelList) =>
        response.labels.filter((option: Spaia_Label) => {
          return option;
        })
      )
    );
  }
  displayLabel(state: Spaia_Label): string {
    return state && state.name ? state.name : '';
  }
  createLabel(): void {
    console.timeLog('gcd ');
    this.labelService
      .createLabel({ body: { name: this.labelControl.value } })
      .subscribe((label) => this.linkLabel.emit(label));
  }
  removeLabel(ev: any): void {
    console.log('rem', ev);
  }
  addLabel(): void {
    this.linkLabel.emit(this.labelControl.value);
  }
  submitLabel(ev: QueryList<MatOption>): void {
    if (ev.first.value.name) {
      console.log(ev.first.value.name);
    } else {
      // this.addLabel();
    }
  }
  optionActivated(ev: any): void {
    console.log(ev);
  }
}
