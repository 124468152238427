import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { ClassificationComponent } from './classification/classification.component';
import { DeviceListComponent } from './device/device-list/device-list.component';
import { DeviceSettingsComponent } from './device/device-settings/device-settings.component';
import { LoginComponent } from './admin/login/login.component';
import { VerifyEmailComponent } from './admin/verify-email/verify-email.component';
import { DataExplorerComponent } from './data-explorer/data-explorer.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';

const routes: Routes = [
  {
    path: '',
    component: SplashScreenComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AngularFireAuthGuard],
  },
  {
    path: 'classification',
    component: ClassificationComponent,
    canActivate: [AngularFireAuthGuard],
  },
  {
    path: 'data/explorer',
    component: DataExplorerComponent,
    canActivate: [AngularFireAuthGuard],
  },
  {
    path: 'devices',
    component: DeviceListComponent,
    canActivate: [AngularFireAuthGuard],
  },
  {
    path: 'device/:deviceId',
    component: DeviceSettingsComponent,
    canActivate: [AngularFireAuthGuard],
  },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: LoginComponent },
  { path: 'verify-email', component: VerifyEmailComponent },
  { path: 'impressum', component: ImpressumComponent },
  { path: 'privacy', component: PrivacyComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
