<div class="container mx-auto px-4 py-8">
  <h1 class="text-2xl font-bold mb-4">Impressum</h1>
  <div class="mb-6">
    <h2 class="text-xl font-semibold mb-2">Webseitenbetreiber</h2>
    <p>Thomas Cox</p>
  </div>
  <div class="mb-6">
    <h2 class="text-xl font-semibold mb-2">
      Informationen über das Unternehmen
    </h2>
    <p>UG (haftungsbeschränkt)</p>
    <p>Schönholzer Str. 8a</p>
    <p>Berlin, 13187</p>
    <p>
      <a href="mailto:tom@playstateprojects.com"
        >tom&#64;playstateprojects.com</a
      >
    </p>
    <p><a href="tel:+4917680690803">017680690803</a></p>
  </div>
  <div class="mb-6">
    <h2 class="text-xl font-semibold mb-2">
      Informationen über die Unternehmensregistrierung
    </h2>
    <p>37/473/51397</p>
    <p>HRB 249133 B</p>
    <p>Stadt Berlin</p>
  </div>
</div>
