import { ElementRef, Injectable } from '@angular/core';
import { Region, Spaia_Event } from '../api/models';
import { EventsService } from '../api/services';

declare var cv: any;
@Injectable({
  providedIn: 'root',
})
export class BlobDetectorService {
  constructor(private eventService: EventsService) {}
  guessRegions(
    imgElement: ElementRef,
    imgElementB: ElementRef,
    event: Spaia_Event
  ): void {
    if (
      !imgElementB ||
      !imgElement ||
      !imgElementB.nativeElement ||
      !imgElement.nativeElement ||
      !imgElementB.nativeElement.width ||
      !imgElement.nativeElement.width ||
      (event.regions && event.regions?.length)
    )
      return;

    imgElementB.nativeElement.crossOrigin = 'Anonymous';
    imgElement.nativeElement.crossOrigin = 'Anonymous';
    let matB;
    let mat;
    try {
      matB = cv.imread(imgElementB.nativeElement.id);
      mat = cv.imread(imgElement.nativeElement.id);
    } catch {
      return;
    }
    let frame1gray = new cv.Mat(mat.size(), cv.CV_8U);
    let frame2gray = new cv.Mat(mat.size(), cv.CV_8U);
    let dst = new cv.Mat(mat.size(), cv.CV_8U);
    let dif = new cv.Mat(mat.size(), cv.CV_8U);
    cv.cvtColor(mat, frame1gray, cv.COLOR_RGBA2GRAY, 0);
    cv.cvtColor(matB, frame2gray, cv.COLOR_RGBA2GRAY, 0);
    let anchor = new cv.Point(-1, -1);
    let ksize = new cv.Size(19, 19);
    cv.blur(frame1gray, frame1gray, ksize, anchor, cv.BORDER_DEFAULT);
    cv.blur(frame2gray, frame2gray, ksize, anchor, cv.BORDER_DEFAULT);
    let mask = new cv.Mat();
    cv.subtract(frame1gray, frame2gray, dst, mask, -1);

    cv.threshold(dst, dif, 90, 255, cv.THRESH_BINARY);
    let contours = new cv.MatVector();
    let hierarchy = new cv.Mat();
    cv.findContours(
      dif,
      contours,
      hierarchy,
      cv.RETR_CCOMP,
      cv.CHAIN_APPROX_SIMPLE
    );

    let contoursColor = new cv.Scalar(255, 0, 255);
    let rectangleColor = new cv.Scalar(200, 200, 20);
    for (let i = 0; i < contours.size(); ++i) {
      let cnt = contours.get(i);
      let rect = cv.boundingRect(cnt);
      cv.drawContours(mat, contours, 0, contoursColor, 1, 8, hierarchy, 100);
      let point1 = new cv.Point(rect.x, rect.y);
      let point2 = new cv.Point(rect.x + rect.width, rect.y + rect.height);
      cv.rectangle(dif, point1, point2, rectangleColor, 1, cv.LINE_AA, 0);
      const region: Region = {
        x: rect.x,
        y: rect.y,
        w: rect.width,
        h: rect.height,
        labels: [],
        eventId: event.id,
      };

      this.eventService
        .createRegion({ body: region })
        .subscribe((data: Region) => {
          event.regions?.push(data);
        });
      cnt.delete();
    }
    mat.delete();
    matB.delete();
    frame1gray.delete();
    frame2gray.delete();
    dif.delete();
    dst.delete();
    hierarchy.delete();
    return;
  }
}
