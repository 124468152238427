<mat-tab-group mat-align-tabs="center" class="w-full">
  <mat-tab label="Filters">
    <mat-accordion>
      <mat-expansion-panel class="mat-elevation-z0" expanded="true">
        <mat-expansion-panel-header>
          <mat-panel-title>Devices</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-selection-list
          #list
          (selectionChange)="filterDevices(list.selectedOptions.selected)"
        >
          <mat-list-option
            *ngFor="let device of devices"
            [value]="device.id"
            checkboxPosition="before"
            [selected]="true"
          >
            {{ device.name }}
          </mat-list-option>
        </mat-selection-list>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>Activity Filters</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-checkbox
          class=""
          [checked]="allImages"
          (change)="setAllImages($event.checked)"
          matTooltip="Displays all images regardless of event."
        >
        All Images
        </mat-checkbox>
        <mat-checkbox
          class=""
          [checked]="hasRegions"
          (change)="setHasRegions($event.checked)"
          matTooltip="Displays only images with marked regions"
        >
          Images with events
        </mat-checkbox>
        <mat-checkbox
          class=""
          [checked]="hasNoRegions"
          (change)="setHasNoRegions($event.checked)"
          matTooltip="Displays only images with no marked regions"
        >
          Images without events
        </mat-checkbox>
        <mat-checkbox
          class=""
          [checked]="hasLabels"
          (change)="setHasLabels($event.checked)"
          matTooltip="Displays only images with marked regions that have identifying labels."
        >
          Labeled events
        </mat-checkbox>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>Date Range</mat-panel-title>
        </mat-expansion-panel-header>
        <!-- ----------------date picker----------------------- -->
        <mat-form-field>
          <mat-date-range-input
            [formGroup]="range"
            [rangePicker]="picker"
            (dateChange)="updateAfter()"
          >
            <input
              matStartDate
              formControlName="start"
              placeholder="Start date"
              (dateChange)="updateAfter()"
              readonly="true"
            />
            <input
              matEndDate
              formControlName="end"
              placeholder="End date"
              (dateChange)="updateBefore()"
              readonly="true"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error
            *ngIf="range.controls.start.hasError('matStartDateInvalid')"
            >Invalid start date</mat-error
          >
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
            >Invalid end date</mat-error
          >
        </mat-form-field>
        <!-- ----------------end date picker------------------- -->
        <mat-checkbox
          class=""
          [checked]="ascending"
          (change)="setAscending($event.checked)"
        >
          Sort dates from the first to the most recent.
        </mat-checkbox>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="flex w-full items-center p-4">
      <button mat-raised-button (click)="downloadAllData()">
        Download All data
      </button>
    </div>
  </mat-tab>
  <mat-tab label="Data">
    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>Environmental Conditions</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-list *ngIf="activeEvent">
        <mat-list-item>
          <h2 class="mat-h2">Device ID: {{ activeEvent.deviceId }}</h2>
        </mat-list-item>
        <mat-list-item>
          <h2 class="mat-h2">Temperature : {{ temperature }}&deg;</h2>
        </mat-list-item>
        <mat-list-item>
          <div fxLayout="column">
            <div *ngFor="let data of activeEvent.sensorData; let i = index">
              {{ sensorNames[i] }}':' {{ data.value }}
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
   
  </mat-tab>
</mat-tab-group>
