import { Component, OnInit } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { CommonModule } from '@angular/common';
import { EventFilters } from '../api/models/events-response';
import { EventsService } from '../api/services';
import { Spaia_Event } from '../api/models';
import { getTemp } from 'src/utilities/event_utils';
import { SidebarComponent } from '../classification/sidebar/sidebar.component';

export interface DataPoint {
  time: Date;
  media: string[];
  temperature: number;
  temperatures: number[];
  count: number;
}
const formatDateForGraphLabel = (date: Date) => {
  return (
    date.toLocaleString('en-US', {
      month: 'short', // abbreviated month name
      day: 'numeric', // numeric day
      hour: 'numeric', // numeric hour
      hour12: false, // using 24-hour format
    }) + ':00'
  );
};
const getHourKey = (dateString: string): string => {
  return dateString.slice(0, 13); // Gets 'YYYY-MM-DDTHH'
};
const summarizeEventsByHour = (events: Spaia_Event[]): DataPoint[] => {
  const summaryMap = new Map<string, DataPoint>();

  events.forEach((event) => {
    const hourKey = getHourKey(event.time ?? new Date().toISOString());
    const existingEntry = summaryMap.get(hourKey);

    if (existingEntry) {
      existingEntry.count += 1;
      event.media.forEach((media) => {
        if (media.fileId && !existingEntry.media.includes(media.fileId)) {
          existingEntry.media.push(media.fileId);
        }
      });
      const temp = getTemp(event);
      temp && existingEntry.temperatures.push(temp);
      //average the temperatures array
      existingEntry.temperature =
        existingEntry.temperatures.reduce((acc, curr) => acc + curr, 0) /
        existingEntry.temperatures.length;
    } else {
      const t = new Date(hourKey);
      summaryMap.set(hourKey, {
        time: new Date(hourKey + ':00'),
        count: 1,
        media: event.media.map((medi) => medi.fileId).filter((id) => !!id),
        temperatures: [getTemp(event)],
        temperature: getTemp(event),
      });
    }
  });

  return Array.from(summaryMap.values());
};
@Component({
  selector: 'app-data-explorer',
  templateUrl: './data-explorer.component.html',
  styleUrls: ['./data-explorer.component.scss'],
  standalone: true,
  imports: [BaseChartDirective, CommonModule, SidebarComponent],
})
export class DataExplorerComponent implements OnInit {
  eventFilters: EventFilters = {
    offset: 0,
    deviceIds: [],
    limit: 10000,
    hasRegions: true,
    ascending: true,
  };
  events: Spaia_Event[] = [];
  public lineChartData: ChartData<'line'>;

  public lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,
  };
  public lineChartType: ChartType = 'line';

  getEvents(filters: EventFilters = this.eventFilters): void {
    this.eventFilters = { ...this.eventFilters, ...filters };
    this.eventService.getEvents(this.eventFilters).subscribe(({ events }) => {
      events ? (this.events = events) : (this.events = [{ media: [] }]);
      const dataPoints: DataPoint[] = [];
      if (!events) return;
      events.forEach((event) => {
        const time = new Date(event.time ?? '') ?? new Date();
      });
      const dps = summarizeEventsByHour(events);

      const tempData = {
        data: dps.map((dp) => dp.temperatures[0]), // Temperature data
        label: 'Temperature',
        borderColor: '#999999',
        backgroundColor: '#999999', // Teal, transparent
        pointBackgroundColor: '#999999',
        pointBorderColor: '#999999',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(0, 128, 128, 0.8)',
        pointStyle: dps.map((dp) => dp.media[0]),
        fill: false, // Ensures no fill under the line
      };
      const countData = {
        data: dps.map((dp) => dp.count), // Temperature data
        label: 'Insect Activity',
        borderColor: '#3cf4a2', // This is not a standard color, you'll need to specify an RGB
        backgroundColor: '#3cf4a2', // Yellow-orange, transparent
        pointBackgroundColor: '#3cf4a2',
        pointBorderColor: '#3cf4a2',
        pointHoverBackgroundColor: '#3cf4a2',
        pointHoverBorderColor: 'rgba(255, 165, 0, 0.8)',
        fill: false, // Ensures no fill under the line
      };
      this.lineChartData = {
        datasets: [tempData, countData],
        labels: dps.map((dp) => formatDateForGraphLabel(dp.time)),
      };
    });
  }
  constructor(public eventService: EventsService) {}

  ngOnInit(): void {
    this.getEvents();
  }
}
