import { Spaia_Event } from 'src/app/api/models';

const getTemp = (event: Spaia_Event): number => {
  const tempSensors = event.sensorData?.filter((data) => {
    return data?.sensorId == 1;
  });
  return tempSensors?.length ? tempSensors[0].value ?? 0 : 0;
};

export { getTemp };
