import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { interval } from 'rxjs';
import { Device, Region, Spaia_Event } from '../api/models';
import { EventFilters } from '../api/models/events-response';
import { blankRegion } from '../api/models/region';
import { EventsService } from '../api/services';
import { RegionService } from '../api/services/region.service';
import { BlobDetectorService } from './blob-detector.service';
import { LabelDialogComponent } from './label-dialog/label-dialog.component';

import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

declare var cv: any;

@Component({
  selector: 'app-classification',
  templateUrl: './classification.component.html',
  styleUrls: ['./classification.component.scss'],
})
export class ClassificationComponent implements OnInit {
  @ViewChild('imgElement')
  public imgElement: ElementRef;
  @ViewChild('imgElementB')
  public imgElementB: ElementRef;

  availableDevices: Device[] = [];
  events: Spaia_Event[] = [{ media: [] }];
  activeEvent: number = 0;
  selectedEvents: number[] = [0];
  scale: number = 0.5;
  @ViewChild('holder')
  holder?: ElementRef;
  @ViewChild('filmstrip')
  filmstrip?: ElementRef;
  dragPosition = { x: 0, y: 0 };
  activeRegion: Region = { ...blankRegion };
  selectRegions: boolean = false;
  private previousDragValue = { x: 0, y: 0 };
  newRegion: Region = { ...blankRegion };
  private creatingRegion = false;
  eventFilters: EventFilters = { offset: 0 };
  noMoreEvents = true;
  imageIndex = 0;
  foundRects = false;
  // pulse = interval(1500).subscribe(() => {
  //   if (this.imageIndex < this.events[this.activeEvent].media.length - 1) {
  //     this.imageIndex++;
  //   } else {
  //     this.imageIndex = 0;
  //   }
  // });
  activeImage = true;

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.events[this.activeEvent].media[0].fileId) {
      this.getImageDimension(this.events[this.activeEvent].media[0].fileId);
    }
  }

  constructor(
    public eventService: EventsService,
    public dialog: MatDialog,
    private regionService: RegionService,
    private blobDetector: BlobDetectorService,
    public confirmationDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.calculateLimit();
  }
  detectInsects() {
    // before detecting the face we need to make sure that
    // 1. OpenCV is loaded
    // 2. The classifiers have been loaded
  }
  downloadData() {
    console.log('downloading all data');
    const unlimttedFilter: EventFilters = { ...this.eventFilters };
    unlimttedFilter.limit = 10000;
    this.eventService.getEvents(unlimttedFilter).subscribe(({ events }) => {
      events?.forEach((dataPoint, index)=>{
        this.downloadImage(dataPoint, index)
      })

    });
  }
  downloadImage(activeEvent: Spaia_Event, fileName:number|null = null) {
    if (!activeEvent.media || !activeEvent.media.length) return;
    let newName = "SPAIA_image";
    if(fileName === null){
      newName = activeEvent.deviceId + '_' + activeEvent.time;
    }else{
      newName = fileName.toString()
    }
    
    fetch(
      'https://imagedelivery.net/fo3ozqx41r8Hdje7X3DFaQ/' +
        activeEvent.media[0].fileId +
        '/w=2560'
    )
      .then((response) => response.blob()) // Convert the response to a Blob
      .then((blob) => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);
        // Create a temporary anchor tag to initiate download
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // Set the download name
        a.download = newName + '.jpg';
        // Append the anchor to the body
        document.body.appendChild(a);
        // Trigger the download by simulating a click
        a.click();
        // Clean up by revoking the object URL and removing the anchor
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch(() => {
        console.error('Could not fetch image');
      });
  }

  getEvents(filters: EventFilters = this.eventFilters): void {
    this.calculateLimit();

    this.eventFilters = { ...this.eventFilters, ...filters };
    this.eventService.getEvents(this.eventFilters).subscribe(({ events }) => {
      events ? (this.events = events) : (this.events = [{ media: [] }]);
      this.prepareEvents();

      this.noMoreEvents =
        this.eventService.count - 1 <
        (this.eventFilters.limit ? this.eventFilters.limit : 0) +
          (this.eventFilters.offset ? this.eventFilters.offset : 0);
    });
  }
  prepareEvents(): void {
    if (
      this.events[this.activeEvent] &&
      this.events[this.activeEvent].media &&
      this.events[this.activeEvent].media.length &&
      typeof this.events[this.activeEvent].media[0].fileId === 'string'
    ) {
      this.getImageDimension(this.events[this.activeEvent].media[0].fileId);
    }
  }
  loadedElements: Array<String> = [];

  guessRegions(): void {
    this.blobDetector.guessRegions(
      this.imgElement,
      this.imgElementB,
      this.events[this.activeEvent]
    );
    this.scale = 1280 / this.holder?.nativeElement.offsetWidth;
  }
  deleteRegion(regionId: number): void {
    this.events[this.activeEvent].regions = this.events[
      this.activeEvent
    ].regions?.filter((region) => regionId != region.id);
  }
  getImageDimension(imageUrl: string): void {
    const img = new Image();

    img.onload = (event) => {
      const loadedImage: any = event.currentTarget;
      if (this.holder) {
        this.scale = loadedImage.width / this.holder.nativeElement.offsetWidth;
      }
    };

    img.src =
      'https://imagedelivery.net/fo3ozqx41r8Hdje7X3DFaQ/' +
      imageUrl +
      '/public';
  }
  calculateLimit(): void {
    const width = this.filmstrip?.nativeElement.offsetWidth;
    //number of photos to display in the film strip
    const photoCount = Math.floor(width / 102);
    this.eventFilters.limit = photoCount ? photoCount : 5;
  }
  nextEvents(): void {
    this.activeEvent = 0;
    const next =
      (this.eventFilters.offset ? this.eventFilters.offset : 0) +
      (this.eventFilters.limit ? this.eventFilters.limit : 0);
    if (next < this.eventService.count - 1) {
      this.eventFilters.offset = next;
      this.getEvents();
    } else {
      this.eventFilters.offset =
        this.eventService.count -
        1 -
        (this.eventFilters.offset ? this.eventFilters.offset : 0);
      this.noMoreEvents = true;
    }
  }
  previousEvents(): void {
    this.eventFilters.offset =
      (this.eventFilters.offset ? this.eventFilters.offset : 0) -
      (this.eventFilters.limit ? this.eventFilters.limit : 0);
    if (this.eventFilters.offset < 0) {
      this.eventFilters.offset = 0;
    }
    this.getEvents();
  }
  toggleImage() {
    this.activeImage = this.activeImage ? false : true;
  }

  setActiveRegion(region: Region) {
    this.activeRegion = region;
  }
  startRegion(event: MouseEvent) {
    event.preventDefault();
    this.creatingRegion = true;
    this.newRegion.x = (event.offsetX - 2) * this.scale;
    this.newRegion.y = (event.offsetY - 2) * this.scale;
    this.events[this.activeEvent].regions?.push(this.newRegion);
  }
  drawRegion(event: MouseEvent) {
    event.preventDefault();
    if (this.creatingRegion) {
      this.newRegion.w = (event.offsetX - 2) * this.scale - this.newRegion.x;
      this.newRegion.h = (event.offsetY - 2) * this.scale - this.newRegion.y;
    }
  }
  addNewRegion(event: MouseEvent): void {
    event.preventDefault();
    if (this.creatingRegion && this.newRegion.h > 15 && this.newRegion.w > 15) {
      const newRegionCopy = { ...this.newRegion };
      newRegionCopy.eventId = this.events[this.activeEvent].id;
      newRegionCopy.x = Math.round(newRegionCopy.x);
      newRegionCopy.y = Math.round(newRegionCopy.y);
      newRegionCopy.h = Math.round(newRegionCopy.h);
      newRegionCopy.w = Math.round(newRegionCopy.w);
      this.creatingRegion = false;
      this.events[this.activeEvent].regions?.pop();
      this.events[this.activeEvent].regions?.push(newRegionCopy);
      this.eventService
        .createRegion({ body: newRegionCopy })
        .subscribe((data: Region) => {
          newRegionCopy.id = data.id;
        });
      this.newRegion = { ...blankRegion };
    } else {
      return;
    }
  }
  verifyEvent(eventIndex: number): void {
    const eventId = this.events[eventIndex].id;
    if (eventId) {
      this.eventService.verifyEvent(eventId).subscribe(
        () => {
          this.nextEvent();
        },
        (err: Error) => {
          console.warn(err);
        }
      );
    }
  }
  deleteEvents(): void {
    this.selectedEvents.forEach((eventIndex) => this.deleteEvent(eventIndex));
  }
  deleteEvent(eventIndex: number): void {
    const eventId = this.events[eventIndex].id;
    if (!eventId) return;
    if (this.events[eventIndex].regions?.length) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '250px',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          // Proceed with deletion only if user confirms
          this.proceedWithDeletion(eventId, eventIndex);
        }
      });
    } else {
      // No regions, proceed with deletion
      this.proceedWithDeletion(eventId, eventIndex);
    }
  }
  proceedWithDeletion(eventId: number, eventIndex: number): void {
    if (eventId) {
      this.eventService.deleteEvent(eventId).subscribe(
        () => {
          this.events.splice(eventIndex, 1);
          if (this.activeEvent > this.events.length - 1) {
            this.activeEvent = this.events.length - 1;
          }
          this.getEvents();
        },
        (err) => {
          console.warn(err);
        }
      );
    }
  }
  nextEvent(): void {
    if (this.activeEvent < this.events.length - 1) {
      this.activeEvent++;
    } else {
      this.activeEvent = 0;
    }
    this.prepareEvents();
  }
  openDialog(region: Region): void {
    const dialogRef = this.dialog.open(LabelDialogComponent, {
      width: '450px',
      data: region,
      autoFocus: true,
    });
  }
}
