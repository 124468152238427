<div fxLayout="column">
  <h1 mat-dialog-title>Labels</h1>
  <div mat-dialog-content>
    <app-label-selector
      [labels]="region.labels"
      (linkLabel)="linkLabel($event)"
      fxFlex
    ></app-label-selector>
  </div>
  <mat-chip-listbox aria-label="Labels">
    <mat-chip *ngFor="let label of region.labels">
      {{ label.name }}
      <mat-icon matChipRemove (click)="delinkLabel(label)">cancel</mat-icon>
    </mat-chip>
  </mat-chip-listbox>
  <div mat-dialog-actions fxLayoutAlign="flex-end" fxLayout="row" fxFlex>
    <button mat-raised-button mat-button (click)="onNoClick()">cancel</button>
    <button
      mat-raised-button
      [mat-dialog-close]="region"
      cdkFocusInitial
      color="primary"
    >
      Ok
    </button>
  </div>
</div>
