import { Component } from '@angular/core';
import interact from 'interactjs';
import { DeviceService, EventsService } from 'src/app/api/services';
import { ActivatedRoute } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { DeviceSetting } from 'src/app/api/models/device-registration-info';

@Component({
  selector: 'app-area-of-interest',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './area-of-interest.component.html',
  styleUrl: './area-of-interest.component.scss',
})
export class AreaOfInterestComponent {
  deviceId: string;
  imgSrc = '';
  areaOfInterest: { x: number; y: number; width: number; height: number } = {
    x: 1,
    y: 1,
    width: 1,
    height: 1,
  };
  getImage() {
    this.eventService
      .getEvents({ deviceIds: [parseInt(this.deviceId)], limit: 5 })
      .subscribe(({ events }) => {
        if (!events) return;
        const foundEvent = events.find((event) =>
          event.media.some((media) => media.fileId)
        );
        if (foundEvent) {
          const mediaItem = foundEvent.media.find((media) => media.fileId);
          if (mediaItem) {
            this.imgSrc = `https://imagedelivery.net/fo3ozqx41r8Hdje7X3DFaQ/${mediaItem.fileId}/w=640`;
          }
        }
      });
  }
  //this runs when the image is loaded in the html
  getAOI() {
    this.deviceService
      .getSetting({ deviceId: parseInt(this.deviceId) })
      .subscribe((settings) => {
        const setting = settings.find((setting) => {
          return setting.name == 'AreaOfInterest';
        });
        if (!setting?.value) return;
        const img = document.getElementById('imgElement');
        if (!img) return;
        const rect = img.getBoundingClientRect();
        const savedRect = JSON.parse(setting.value);
        console.log("sr", savedRect)
        const aoi = document.getElementById('aoi');
        if(!aoi) return;
        aoi.style.left = `${savedRect.x * rect.width }px`;
     
          aoi.style.top = `${savedRect.y * rect.height}px`;
          aoi.style.width = `${savedRect.width * rect.width}px`;
          aoi.style.height = `${savedRect.height * rect.height}px`;
      });
  }
  saveAOI = () => {
    this.deviceService
      .postSetting({
        deviceId: parseInt(this.deviceId),
        body: {
          deviceId: parseInt(this.deviceId),
          name: 'AreaOfInterest',
          value: JSON.stringify(this.areaOfInterest),
        },
      })
      .subscribe((data) => {
        console.log(data);
      });
  };

  constructor(
    public eventService: EventsService,
    private route: ActivatedRoute,
    private deviceService: DeviceService
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.deviceId = params['deviceId'];
      this.getImage();
    });
  }
  ngAfterViewInit() {
    interact('#aoi').resizable({
      edges: { top: true, left: true, bottom: true, right: true },
      modifiers: [
        interact.modifiers.restrictSize({
          min: { width: 10, height: 10 },
          max: 'parent',
        }),
      ],
      listeners: {
        move: (event) => {
          let { x, y } = event.target.dataset;

          x = (parseFloat(x) || 0) + event.deltaRect.left;
          y = (parseFloat(y) || 0) + event.deltaRect.top;

          Object.assign(event.target.style, {
            width: `${event.rect.width}px`,
            height: `${event.rect.height}px`,
            transform: `translate(${x}px, ${y}px)`,
          });

          Object.assign(event.target.dataset, { x, y });
        },
        end: (event) => {
          const img = document.getElementById('imgElement');
          if (!img) return;
          console.log('go');
          // Calculate the dimensions and position of AOI as a percentage of the image dimensions
          const rect = img.getBoundingClientRect();

          const width = event.deltaRect.width;
          const height = event.deltaRect.height;
          console.log('event deltarect', event.deltaRect);
          console.log('event rect', event.rect);
          console.log('imgr', rect);
          this.areaOfInterest = {
            x: (event.rect.left - rect.x) / rect.width,
            y: (event.rect.top - rect.y) / rect.height,
            width: event.rect.width / rect.width,
            height: event.rect.height / rect.height,
          };
          // const testBox = document.getElementById('testBox');
          // if(!testBox) return;
          // testBox.style.left = `${settingRect.x * rect.width }px`;
          // testBox.style.top = `${settingRect.y * rect.height}px`;
          // testBox.style.width = `${settingRect.width * rect.width}px`;
          // testBox.style.height = `${settingRect.height * rect.height}px`;
          // testBox.style.pointerEvents = 'none';
        },
      },
    });
  }
}
