import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public userData: Observable<firebase.User | null>;
  constructor(public angularFireAuth: AngularFireAuth, public router: Router) {
    this.userData = this.angularFireAuth.authState;
  }
  /* Sign up */
  signUp = (email: string, password: string) => {
    return this.angularFireAuth.createUserWithEmailAndPassword(email, password);
  };
  /* Sign in */
  login = (email: string, password: string) => {
    return this.angularFireAuth.signInWithEmailAndPassword(email, password);
  };
  signInWithGoogle = () => {
    return this.angularFireAuth.signInWithPopup(
      new firebase.auth.GoogleAuthProvider()
    );
  };
  resetPassword = (email: string) => {
    return this.angularFireAuth.sendPasswordResetEmail(email);
  };

  /* Sign out */
  signOut() {
    this.angularFireAuth.signOut();
  }
}
