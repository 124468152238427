<mat-sidenav-container class="flex flex-col h-full min-h-full">
  <mat-sidenav
    #sidenav
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [(opened)]="navOpened"
    class="bg-gray-800 text-white h-full min-h-full"
  >
    <div class="flex flex-col justify-between h-full">
      <app-nav></app-nav>
      <app-navbar></app-navbar>
    </div>
  </mat-sidenav>

  <mat-sidenav-content
    class="flex flex-col items-stretch flex-grow overflow-auto h-full min-h-full"
  >
    <div class="h-full w-full">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
