import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { LocationService } from 'src/app/location.service';
import { DeviceService } from 'src/app/api/services';
import { DeviceSetting } from 'src/app/api/models/device-registration-info';

@Component({
  selector: 'app-field-agent',
  templateUrl: './field-agent.component.html',
  styleUrls: ['./field-agent.component.scss'],
})
export class FieldAgentComponent implements OnInit {
  systemControl = new UntypedFormControl();
  formGroup: UntypedFormGroup;
  coordinates: [number, number] = [30.5595, -22.9375];
  feature = { type: 'Point', coordinates: this.coordinates };
  settingsLoaded = false;
  layerPaint = {
    'circle-radius': 7,
    'circle-color': '#1cc732',
  };
  saveSetting = (setting: DeviceSetting) => {
    this.deviceService
      .postSetting({
        deviceId: 1,
        body: {
          deviceId: 1,
          name: setting.name,
          value: setting.value,
        },
      })
      .subscribe((data) => {
        console.log(data);
      });
  };

  constructor(
    private locator: LocationService,
    private formBuilder: UntypedFormBuilder,
    private deviceService: DeviceService
  ) {
    this.formGroup = this.formBuilder.group({
      sleepTime: '1',
      wakeTime: 20,
      sensitivity: '',
      captureSchedule: '',
      uploadSchedule: '',
      realtimeUploads: false,
    });
  }

  ngOnInit(): void {}
}
