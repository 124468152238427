<div
  class="flex h-full flex-col w-full"
  (mousedown)="setActive()"
  [class.active]="isActive"
  (mouseup)="releaseRegion(region)"
>
  <div class="region-title flex justify-end items-center">
    <div
      class="label ml-2"
      *ngIf="
        region && region.labels && region.labels.length && region.labels[0].name
      "
    >
      <span>{{ region.labels[0].name }}</span>
    </div>
    <mat-icon
      aria-hidden="false"
      aria-label="Example home icon"
      [inline]="true"
      class="material-icons-outlined mb-1"
      (click)="delete()"
    >
      delete
    </mat-icon>
  </div>
  <div class="region-body" fxFill>
    <ng-content></ng-content>
    <div
      class="right-handle"
      cdkDrag
      cdkDragBoundary=".img-holder"
      (cdkDragMoved)="dragRight($event)"
      cdkDragLockAxis="x"
      cdkDragPreviewContainer="parent"
      [cdkDragFreeDragPosition]="dragPosition"
    ></div>
    <div
      class="down-handle"
      cdkDrag
      cdkDragBoundary=".img-holder"
      (cdkDragMoved)="dragDown($event)"
      cdkDragLockAxis="y"
      cdkDragPreviewContainer="parent"
      [cdkDragFreeDragPosition]="dragPosition"
    ></div>
  </div>
</div>
