import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CameraSettings } from './device.interface';

@Injectable({
  providedIn: 'root',
})
export class LocalService {
  deviceAddress: String = '';
  constructor(private http: HttpClient) {}
  setIp = (ip: string) => {
    this.deviceAddress = 'http://' + ip;
  };
  getvideo = () => {
    return this.http.get(this.deviceAddress + '/test');
  };
  getCameraSettings = () => {
    return this.http.get<CameraSettings>(
      this.deviceAddress + '/settings/camera'
    );
  };
  incramentExposureBias = () => {
    return this.http.get<CameraSettings>(
      this.deviceAddress + '/exposureBias/more'
    );
  };
  decramentExposureBias = () => {
    return this.http.get<CameraSettings>(
      this.deviceAddress + '/exposureBias/less'
    );
  };
  incramentBrightness = () => {
    return this.http.get<CameraSettings>(
      this.deviceAddress + '/brightness/more'
    );
  };
  decramentBrightness = () => {
    return this.http.get<CameraSettings>(
      this.deviceAddress + '/brightness/less'
    );
  };
  startDetector = () => {
    return this.http.get<CameraSettings>(this.deviceAddress + '/start');
  };
  stopDetector = () => {
    return this.http.get<CameraSettings>(this.deviceAddress + '/stop');
  };
}
