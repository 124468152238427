import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Device } from 'src/app/api/models';
import { DeviceService } from 'src/app/api/services';
import { CameraSettings } from '../device.interface';
import { LocalService } from '../local.service';

@Component({
  selector: 'app-device-settings',
  templateUrl: './device-settings.component.html',
  styleUrls: ['./device-settings.component.scss'],
})
export class DeviceSettingsComponent implements OnInit {
  device?: Device;
  cameraSettings: CameraSettings = {};
  deviceConnected = false;
  constructor(
    private route: ActivatedRoute,
    private deviceService: DeviceService,
    private localService: LocalService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.deviceService
        .getDevice({ deviceId: params.deviceId })
        .subscribe((result) => {
          this.device = result;
          if (this.device.ip) {
            this.localService.setIp(this.device.ip);
            this.localService
              .getCameraSettings()
              .pipe(
                map((dev) => {
                  console.log('dev', dev);
                  return dev;
                })
              )
              .subscribe((settings: CameraSettings) => {
                this.deviceConnected = true;
                this.cameraSettings = settings ? settings : {};
              });
          }
        });
    });
  }
  adjustBrightness = (e: number) => {
    if (e > 0) {
      this.localService
        .incramentBrightness()
        .subscribe((res: CameraSettings) => {
          this.cameraSettings.brightness = res.brightness;
        });
    } else {
      this.localService
        .decramentBrightness()
        .subscribe((res: CameraSettings) => {
          this.cameraSettings.brightness = res.brightness;
        });
    }
  };
  adjustExposureBias = (e: number) => {
    if (e > 0) {
      this.localService
        .incramentExposureBias()
        .subscribe((res: CameraSettings) => {
          this.cameraSettings.autoExposureBias = res.autoExposureBias;
        });
    } else {
      this.localService
        .decramentExposureBias()
        .subscribe((res: CameraSettings) => {
          this.cameraSettings.autoExposureBias = res.autoExposureBias;
        });
    }
  };
}
