import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MapMouseEvent } from 'mapbox-gl';
import { DeviceSetting } from 'src/app/api/models/device-registration-info';
import { DeviceService } from 'src/app/api/services';
import { LocationService } from 'src/app/location.service';

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss'],
})
export class SystemComponent implements OnInit {
  systemControl = new UntypedFormControl();
  formGroup: UntypedFormGroup;
  coordinates: [number, number] = [30.5595, -22.9375];
  feature = { type: 'Point', coordinates: this.coordinates };
  settingsLoaded = false;
  layerPaint = {
    'circle-radius': 7,
    'circle-color': '#1cc732',
  };

  saveSetting = (setting: DeviceSetting) => {
    this.deviceService
      .postSetting({
        deviceId: 1,
        body: {
          deviceId: 1,
          name: setting.name,
          value: setting.value,
        },
      })
      .subscribe((data) => {
        console.log(data);
      });
  };
  onDragEnd = (event: MapMouseEvent) => {
    let newCoords = JSON.stringify([event.lngLat.lng, event.lngLat.lat]);
    this.saveSetting({ name: 'location', value: newCoords });
    this.coordinates = [event.lngLat.lng, event.lngLat.lat];
  };
  changeColor(color: string) {
    this.layerPaint = { ...this.layerPaint, 'circle-color': color };
  }
  constructor(
    private locator: LocationService,
    private formBuilder: UntypedFormBuilder,
    private deviceService: DeviceService
  ) {
    this.formGroup = this.formBuilder.group({
      burst: '1',
      captureLimit: '',
      uploadSchedule: '',
      captureSchedule: '',
    });
  }
  findMe(): void {
    this.settingsLoaded = false;
    this.locator.getPosition().then((data) => {
      this.coordinates = [data.lng, data.lat];
      this.saveSetting({
        name: 'location',
        value: JSON.stringify(this.coordinates),
      });
      this.settingsLoaded = true;
    });
  }
  ngOnInit(): void {
    let hasCoords = false;
    this.deviceService.getSetting({ deviceId: 1 }).subscribe((settings) => {
      let calculatedValue: any;
      settings.map((setting) => {
        switch (setting.type) {
          case 'BOOLEAN':
            calculatedValue = setting.value == 'true' ? true : false;
            break;
          case 'NUMBER':
            calculatedValue = Number(setting.value);
            break;
          case 'ARRAY':
            calculatedValue = JSON.parse(setting.value as string);
            break;
          default:
            calculatedValue = setting.value;
        }
        if (setting.name == 'location') {
          this.coordinates = JSON.parse(calculatedValue);
          hasCoords = true;
        } else {
          this.formGroup.patchValue({
            [setting.name as string]: calculatedValue,
          });
        }
      });
      this.settingsLoaded = true;
      if (!hasCoords) {
        this.findMe();
      }
    });
  }
}
