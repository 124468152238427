import { CommonModule, JsonPipe } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  NgZone,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule, MatListOption } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { Device, Region, Spaia_Event } from 'src/app/api/models';
import { EventFilters } from 'src/app/api/models/events-response';
import { LabelList, Spaia_Label } from 'src/app/api/models/label';
import { DeviceService } from 'src/app/api/services';
import { LabelService } from 'src/app/api/services/label.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatListModule,
    MatExpansionModule,
    MatTabsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    JsonPipe,
    MatCheckboxModule,
    MatTooltipModule,
    MatButtonModule,
  ],
})
export class SidebarComponent implements OnInit {
  public devices?: Array<Device> = [];
  sensorNames = ['Temperature', 'Humidity', 'Barometric Pressure'];
  @Input() events!: Array<Spaia_Event>;
  @Output() filterChange = new EventEmitter<EventFilters>();
  @Output() downloadData = new EventEmitter<EventFilters>();
  @Input() activeRegion: Region = { x: 0, y: 0, w: 0, h: 0, labels: [] };
  private _activeEvent: Spaia_Event = { sensorData: [], media: [] };
  @Input() set activeEvent(activeEvent: Spaia_Event) {
    this._activeEvent = activeEvent;
    this.temperature = this.getTemp();
  }
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });
  get activeEvent(): Spaia_Event {
    return this._activeEvent;
  }
  deviceFilter?: number[] = [];
  temperature: string = '';
  labels: Array<Spaia_Label> = [];
  eventFilters: EventFilters = {};
  labelControl = new UntypedFormControl();
  allImages: boolean = false;
  @Input() hasRegions = false;
  @Input() hasLabels = false;
  @Input() ascending = false;
  hasNoRegions = false;

  setAllImages(newVal: boolean) {
    console.log('hhhh');
    this.zone.run(() => {
      if (newVal) {
        this.hasNoRegions = false;
        this.hasLabels = false;
        this.hasLabels = false;
      }
    });
  }

  setHasRegions(newVal: boolean) {
    if (newVal) {
      this.hasNoRegions = false;
      this.hasLabels = false;
      this.allImages = false;
    }
    this.eventFilters = {
      ...this.eventFilters,
      hasRegions: newVal,
    };
    // this.filterChange.emit(this.eventFilters);
  }
  setHasLabels(newVal: boolean) {
    this.zone.run(() => {
      if (newVal) {
        this.hasNoRegions = false;
        this.hasRegions = false;
        this.allImages = false;
      }
    });
    this.eventFilters = {
      ...this.eventFilters,
      hasLabels: newVal,
    };
    // this.filterChange.emit(this.eventFilters);
  }
  setHasNoRegions(newVal: boolean) {
    this.zone.run(() => {
      if (newVal) {
        this.hasRegions = false;
        this.hasLabels = false;
        this.allImages = false;
      }
    });
    this.eventFilters = {
      ...this.eventFilters,
      hasNoRegions: newVal,
    };
    // this.filterChange.emit(this.eventFilters);
  }
  setAscending(newVal: boolean) {
    this.eventFilters = {
      ...this.eventFilters,
      ascending: newVal,
    };
    this.filterChange.emit(this.eventFilters);
  }

  filteredLabels = this.labelControl.valueChanges.pipe(
    startWith(''),
    debounceTime(400),
    distinctUntilChanged(),
    switchMap((val) => {
      return this.filter(val || '');
    })
  );
  filter(val: string): Observable<Spaia_Label[]> {
    return this.labelService.getLabels(val ? { search: val } : {}).pipe(
      map((response: LabelList) =>
        response.labels.filter((option: Spaia_Label) => {
          return option.name;
        })
      )
    );
  }
  updateAfter(): void {
    this.eventFilters = {
      ...this.eventFilters,
      after: this.range.value.start.toISOString(),
    };
    this.filterChange.emit(this.eventFilters);
  }
  updateBefore(): void {
    this.eventFilters = {
      ...this.eventFilters,
      before: this.range.value.end.toISOString(),
    };
    this.filterChange.emit(this.eventFilters);
  }
  constructor(
    private deviceService: DeviceService,
    private labelService: LabelService,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.deviceService.getDevices().subscribe(({ devices }) => {
      this.devices = devices;

      if (this.devices && this.devices[0] && this.devices[0].id) {
        this.eventFilters = {
          ...this.eventFilters,
          deviceIds: this.devices?.map((d) => {
            return d.id as number;
          }),
        };
        this.filterChange.emit(this.eventFilters);
      }
    });
    this.temperature = this.getTemp();
    this.labelService.getLabels().subscribe(({ labels }) => {
      this.labels = labels ? labels : [];
    });
  }
  addLabel(): void {
    const match = this.labels.find(
      (existing) => this.labelControl.value == existing.name
    );
    if (match) {
      this.activeRegion.labels?.push(match);
    }
  }
  private getTemp(): string {
    const tempSensors = this._activeEvent?.sensorData?.filter((data) => {
      return data?.sensorId == 1;
    });
    const temp = tempSensors?.length ? tempSensors[0].value?.toString() : '';

    return temp ? temp : '';
  }
  filterDevices(data?: MatListOption[]): void {
    this.deviceFilter = data?.map((d: MatListOption) => d.value as number);
    this.eventFilters = { ...this.eventFilters, deviceIds: this.deviceFilter };
    this.filterChange.emit(this.eventFilters);
  }

  deleteLabel(label: Spaia_Label): void {
    console.log('label');
  }
  downloadAllData(): void {
    console.log('emit download all data');
    this.downloadData.emit();
  }
}
