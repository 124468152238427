import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Device, DeviceList } from '../../api/models';
import { DeviceService } from '../../api/services';
import { AddDeviceDialogComponent } from '../add-device-dialog/add-device-dialog.component';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss'],
})
export class DeviceListComponent implements OnInit {
  devices: Array<Device> = [];
  cols: number;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private deviceService: DeviceService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          if (result.breakpoints[Breakpoints.XSmall]) {
            this.cols = 1;
          } else if (result.breakpoints[Breakpoints.Small]) {
            this.cols = 2;
          } else if (result.breakpoints[Breakpoints.Medium]) {
            this.cols = 3;
          } else {
            this.cols = 4;
          }
        }
      });
  }

  ngOnInit(): void {
    this.getDevices();
  }
  getDevices(): void {
    this.deviceService.getDevices().subscribe(({ devices }) => {
      this.devices = devices ? devices : [];
    });
  }
  openDevice(deviceId: number): void {
    this.router.navigateByUrl('/device/' + deviceId);
  }
  addDevice(): void {
    const dialogRef = this.dialog.open(AddDeviceDialogComponent, {
      width: '450px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getDevices();
    });
  }
  createDevice(): void {
    const dialogRef = this.dialog.open(AddDeviceDialogComponent, {
      width: '450px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getDevices();
    });
  }
}
