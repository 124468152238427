<form fxLayout="column" [formGroup]="formGroup" ngNativeValidate>
  <mat-list fxFill>
    <mat-list-item class="mb2" FxFlex>
      <mat-form-field fxFlex>
        <mat-label>Capture a Maximum of</mat-label>
        <input
          matInput
          #burst
          formControlName="burst"
          (change)="saveSetting({ name: 'burst', value: burst.value })"
        />
      </mat-form-field>
    </mat-list-item>
    <mat-list-item class="mb2">
      <mat-form-field fxFlex>
        <mat-label>Every</mat-label>
        <mat-select
          #captureLimit
          required
          formControlName="captureLimit"
          (selectionChange)="
            saveSetting({ name: 'captureLimit', value: captureLimit.value })
          "
        >
          <mat-option value="1">every second</mat-option>
          <mat-option value="5">every 5 seconds</mat-option>
          <mat-option value="30">every 30 seconds</mat-option>
          <mat-option value="60">every minute</mat-option>
          <mat-option value="3600">every hour</mat-option>
          <mat-option value="86400">every day</mat-option>
        </mat-select>
        <mat-error *ngIf="systemControl.hasError('required')">
          This field is required
        </mat-error>
      </mat-form-field>
    </mat-list-item>
    <mat-list-item class="mb2">
      <mat-form-field fxFlex>
        <mat-label>Upload Data Every </mat-label>
        <mat-select
          required
          formControlName="uploadSchedule"
          #uploadSchedule
          (selectionChange)="
            saveSetting({ name: 'uploadSchedule', value: uploadSchedule.value })
          "
        >
          <mat-option value="0">Real time</mat-option>
          <mat-option value="300">5 min</mat-option>
          <mat-option value="1800">30 mins</mat-option>
          <mat-option value="3600">1 Hour</mat-option>
          <mat-option value="43200">12 Hours</mat-option>
          <mat-option value="86400">24 Hours</mat-option>
        </mat-select>
        <mat-error *ngIf="systemControl.hasError('required')">
          This field is required
        </mat-error>
      </mat-form-field>
    </mat-list-item>
    <mat-list-item class="mb2">
      <mat-form-field fxFlex>
        <mat-label>Sensor Capture Schedule</mat-label>
        <mat-select
          required
          formControlName="captureSchedule"
          #captureSchedule
          (selectionChange)="
            saveSetting({
              name: 'captureSchedule',
              value: captureSchedule.value
            })
          "
        >
          <mat-option value="0">Only on Events</mat-option>
          <mat-option value="300">5 min</mat-option>
          <mat-option value="1800">30 mins</mat-option>
          <mat-option value="3600">1 Hour</mat-option>
          <mat-option value="43200">12 Hours</mat-option>
          <mat-option value="86400">24 Hours</mat-option>
        </mat-select>
        <mat-error *ngIf="systemControl.hasError('required')">
          This field is required
        </mat-error>
        <mat-hint>How often should sensor data be recorded?</mat-hint>
      </mat-form-field>
    </mat-list-item>
    <mat-list-item class="mb3" fxLayout="row" fxFill>
      <h4 mat-line style="margin: 0">Location</h4>
      <span
        mat-line
        class="mat-caption"
        style="font-size: 0.7rem; color: #666"
        >{{ coordinates }}</span
      >

      <button mat-icon-button matListIcon (click)="findMe()">
        <mat-spinner
          color="accent"
          [diameter]="20"
          *ngIf="!settingsLoaded"
        ></mat-spinner>
        <mat-icon *ngIf="settingsLoaded">my_location</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>
  <mat-card class="mapBox" fxFlex>
    <mat-card-content fxFlex>
      <mgl-map
        [style]="'mapbox://styles/notsosapien/cii1z90hg00iqiolzlotxab0a'"
        [zoom]="[14]"
        [center]="coordinates"
        fxFlex
      >
        <mgl-geojson-source id="point" *ngIf="settingsLoaded">
          <mgl-feature
            [properties]=""
            [geometry]="{
              type: 'Point',
              coordinates: coordinates
            }"
            [mglDraggable]="targetLayer"
            (featureDragEnd)="onDragEnd($event)"
          ></mgl-feature>
        </mgl-geojson-source>
        <mgl-layer
          #targetLayer
          class="marker"
          id="point3"
          type="circle"
          source="point"
          [paint]="layerPaint"
          (layerMouseEnter)="changeColor('#1dde36')"
          (layerMouseLeave)="changeColor('#1cc732')"
        ></mgl-layer>
      </mgl-map>
    </mat-card-content>
  </mat-card>
</form>
