/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { User } from '../models/user';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root',
})
export class MeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient,
    firebase: AngularFireAuth
  ) {
    super(config, http, firebase);
  }

  /**
   * Path part for operation getMe
   */
  static readonly GetMePath = '/me';

  /**
   * returns user profile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  async getMe$Response(params?: {}): Promise<
    Observable<StrictHttpResponse<User>>
  > {
    const rb = new RequestBuilder(this.rootUrl, MeService.GetMePath, 'get');
    if (params) {
    }

    return this.http
      .request(
        await rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<User>;
        })
      );
  }

  /**
   * returns user profile
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  async getMe(params?: {}): Promise<Observable<User>> {
    return (await this.getMe$Response(params)).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation updateProfile
   */
  static readonly UpdateProfilePath = '/me';

  /**
   * update the user's profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  async updateProfile$Response(params?: {
    body?: User;
  }): Promise<Observable<StrictHttpResponse<void>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      MeService.UpdateProfilePath,
      'put'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        await rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * update the user's profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  async updateProfile(params?: { body?: User }): Promise<Observable<void>> {
    return (await this.updateProfile$Response(params)).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateUser
   */
  static readonly UpdateUserPath = '/me';

  /**
   * update the user's profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  async updateUser$Response(params?: {
    body?: User;
  }): Promise<Observable<StrictHttpResponse<void>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      MeService.UpdateUserPath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        await rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * update the user's profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  async updateUser(params?: { body?: User }): Promise<Observable<void>> {
    return (await this.updateUser$Response(params)).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }
}
