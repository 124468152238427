import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Spaia_Event } from 'src/app/api/models';
import { EventsService } from 'src/app/api/services';

@Component({
  selector: 'app-filmstrip',
  templateUrl: './filmstrip.component.html',
  styleUrls: ['./filmstrip.component.scss'],
})
export class FilmstripComponent implements OnInit {
  @Input() events!: Spaia_Event[];
  @Input() activeEvent!: number | string;
  @Input() selectedEvents: number[];
  @Output() activeEventChange = new EventEmitter<number>();
  @Output() selectedEventsChange = new EventEmitter<number[]>();

  constructor(private eventsService: EventsService) {}

  ngOnInit(): void {
    console.log(this.events);
  }

  nextItems(): void {
    console.log('next items', this.eventsService.offset);
  }
  prevItems(): void {
    console.log('prev items');
  }
  isSelected(index: number): boolean {
    return this.selectedEvents.some((e) => e === index);
  }

  setActiveEvent(selectedIndex: number, event: MouseEvent): void {
    // Check if Shift or Ctrl key was pressed
    if (event.shiftKey) {
      this.selectedEvents = [];
      for (
        let i = Math.min(selectedIndex, this.activeEvent as number);
        i <= Math.max(selectedIndex, this.activeEvent as number);
        i++
      ) {
        this.selectedEvents.push(i);
      }
    } else if (event.ctrlKey) {
      const i = this.selectedEvents.indexOf(selectedIndex);
      if (i === -1) {
        // Number not found, add it
        this.selectedEvents.push(selectedIndex);
      } else {
        // Number found, remove it
        this.selectedEvents.splice(i, 1);
      }
    } else {
      this.selectedEvents = [selectedIndex];
      this.activeEvent = selectedIndex;
      this.activeEventChange.emit(this.activeEvent);
    }
    this.selectedEventsChange.emit(this.selectedEvents);
    event.preventDefault();
  }
}
