<div class="flex flex-col flex-grow flex-shrink w-full h-full">
  <div class="flex justify-between flex-grow items-center w-full">
    <div class="flex flex-grow justify-center items-center w-9/12">
      <div class="flex w-full mx-10">
        <canvas
          baseChart
          [data]="lineChartData"
          [type]="lineChartType"
          [options]="lineChartOptions"
        >
        </canvas>
      </div>
    </div>
    <div class="sidebar-holder h-full w-4/12">
      <app-sidebar
        [events]="events"
        (filterChange)="getEvents($event)"
        [hasRegions]="true"
        [ascending]="true"
        class="flex w-full h-full"
      ></app-sidebar>
    </div>
  </div>
  <!-- <div fxLayout="row" style="height: 100px" class="filmstrip"></div> -->
</div>
