import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Device } from 'src/app/api/models';
import { DeviceService } from 'src/app/api/services';
import { DeviceListComponent } from '../device-list/device-list.component';

@Component({
  selector: 'app-add-device-dialog',
  templateUrl: './add-device-dialog.component.html',
  styleUrls: ['./add-device-dialog.component.scss'],
})
export class AddDeviceDialogComponent implements OnInit {
  name = new UntypedFormControl('');
  constructor(
    public dialogRef: MatDialogRef<DeviceListComponent>,
    private deviceService: DeviceService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {}
  createDevice = () => {
    this.deviceService
      .createDevice({ body: { name: this.name.value } })
      .subscribe(
        (res) => {
          this.dialogRef.close();
        },
        (err) => {
          console.warn(err);
          this._snackBar.open(
            'Unable to create Device. Please try a differnt name.'
          );
        }
      );
  };
  onSubmit = () => {
    this.deviceService
      .registerDevice({ body: { name: this.name.value } })
      .subscribe(
        (res) => {
          this.dialogRef.close();
        },
        (err) => {
          console.warn(err);
          this._snackBar.open('Device not found.', '', { duration: 3000 });
        }
      );
  };
}
