<div class="flex justify-start items-stretch flex-grow gap-1.5">
  <button
    mat-icon-btn
    class="event-icon flex flex-col justify-center w-22 my-4 p-2"
    [class.active]="i == activeEvent"
    [class.selected]="isSelected(i)"
    *ngFor="let event of events; let i = index"
    (click)="setActiveEvent(i, $event)"
    (contextmenu)="setActiveEvent(i, $event)"
  >
    <img
      *ngIf="event.media && event.media[0] && event.media[0].fileId"
      src="{{
        event.media[0].fileId.indexOf('http') > -1
          ? event.media[0].fileId
          : 'https://imagedelivery.net/fo3ozqx41r8Hdje7X3DFaQ/' +
            event.media[0].fileId +
            '/thumbnail'
      }}"
      alt=""
      class="flex"
    />
    <ng-template #icon>
      <mat-icon aria-hidden="false" aria-label="Example home icon">
        bug_report
      </mat-icon>
    </ng-template>
  </button>
</div>
