/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Region } from '../models/region';

@Injectable({
  providedIn: 'root',
})
export class RegionService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient,
    firebase: AngularFireAuth
  ) {
    super(config, http, firebase);
  }

  static readonly deleteRegionPath = '/region/{regionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `CreateEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteRegion$Response(regionId: number): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RegionService.deleteRegionPath,
      'delete'
    );
    if (regionId) {
      rb.path('regionId', regionId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Region>;
        })
      );
  }
  deleteRegion(regionId: number): Observable<Region> {
    return this.deleteRegion$Response(regionId).pipe(
      map((r: StrictHttpResponse<Region>) => r.body as Region)
    );
  }
  /**
   * Path part for operation updateEvent
   */
  static readonly UpdateRegionPath = '/region/{regionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRegion$Response(params: {
    regionId: number;
    body: Region;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      RegionService.UpdateRegionPath,
      'put'
    );
    if (params) {
      rb.path('regionId', params.regionId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }
  updateRegion(params: { regionId: number; body: Region }): Observable<void> {
    return this.updateRegion$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }
}
