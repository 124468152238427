<!-- footer.component.html -->
<footer>
  <mat-toolbar class="flex justify-between">
    <span class="text-sm"> © 2024 Playstate Projects UG </span>
    <ul id="legal" class="flex">
      <li><a href="/impressum" class="text-xs mr-3">Impressum</a></li>
      <li><a href="/privacy" class="text-xs mr-3">Privacy</a></li>
      <li>
        <a
          href="https://www.termsfeed.com/live/090f0406-3ebe-4e17-9d73-2e477bb69361"
          class="text-xs mr-3"
          target="_BLANK"
          >Terms & Conditions</a
        >
      </li>
    </ul>
  </mat-toolbar>
</footer>
