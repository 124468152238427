/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DeviceList } from '../models/device-list';
import {
  DeviceRegistrationInfo,
  DeviceSetting,
} from '../models/device-registration-info';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Device } from '../models/device';

@Injectable({
  providedIn: 'root',
})
export class DeviceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient,
    firebase: AngularFireAuth
  ) {
    super(config, http, firebase);
  }

  /**
   * Path part for operation getDevices
   */
  static readonly GetDevicesPath = '/devices';

  /**
   * returns all registered devices
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDevices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDevices$Response(params?: {
    /**
     * where to start
     */
    after?: number;

    /**
     * max number of records to return
     */
    limit?: number;
  }): Observable<StrictHttpResponse<DeviceList>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DeviceService.GetDevicesPath,
      'get'
    );
    if (params) {
      rb.query('after', params.after, {});
      rb.query('limit', params.limit, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<DeviceList>;
        })
      );
  }

  /**
   * returns all registered devices
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDevices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDevices(params?: {
    /**
     * where to start
     */
    offeset?: number;

    /**
     * max number of records to return
     */
    limit?: number;
  }): Observable<DeviceList> {
    return this.getDevices$Response(params).pipe(
      map((r: StrictHttpResponse<DeviceList>) => {
        return r.body;
      })
    );
  }
  static readonly CreateDevicePath = '/devices';

  createDevice$Response(params?: {
    body?: DeviceRegistrationInfo;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DeviceService.CreateDevicePath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }
  createDevice(params?: { body?: DeviceRegistrationInfo }): Observable<void> {
    return this.createDevice$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }
  /**
   * Path part for operation registerDevice
   */
  static readonly RegisterDevicePath = '/device/register';

  /**
   * create a record for a new device.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerDevice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerDevice$Response(params?: {
    body?: DeviceRegistrationInfo;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DeviceService.RegisterDevicePath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * create a record for a new device.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerDevice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerDevice(params?: { body?: DeviceRegistrationInfo }): Observable<void> {
    return this.registerDevice$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getDevice
   */
  static readonly GetDevicePath = '/device/{deviceId}';

  /**
   * returns all registered devices
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDevice()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDevice$Response(params: {
    deviceId: number;
  }): Observable<StrictHttpResponse<Device>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DeviceService.GetDevicePath,
      'get'
    );
    if (params) {
      rb.path('deviceId', params.deviceId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Device>;
        })
      );
  }

  /**
   * returns all registered devices
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDevice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDevice(params: { deviceId: number }): Observable<Device> {
    return this.getDevice$Response(params).pipe(
      map((r: StrictHttpResponse<Device>) => r.body as Device)
    );
  }

  /**
   * Path part for operation updateDevice
   */
  static readonly UpdateDevicePath = '/device/{deviceId}';

  /**
   * update a device.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDevice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDevice$Response(params: {
    deviceId: number;
    body?: DeviceRegistrationInfo;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DeviceService.UpdateDevicePath,
      'put'
    );
    if (params) {
      rb.path('deviceId', params.deviceId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * update a device.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDevice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDevice(params: {
    deviceId: number;
    body?: DeviceRegistrationInfo;
  }): Observable<void> {
    return this.updateDevice$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation putSettings
   */
  static readonly postSettingPath = '/device/{deviceId}/setting';

  /**
   * update a device.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDevice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postSetting$Response(params: {
    deviceId: number;
    body?: DeviceSetting;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DeviceService.postSettingPath,
      'post'
    );
    if (params) {
      rb.path('deviceId', params.deviceId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        })
      );
  }
  postSetting(params: {
    deviceId: number;
    body?: DeviceSetting;
  }): Observable<void> {
    return this.postSetting$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }
  getSetting(params: { deviceId: number }): Observable<Array<DeviceSetting>> {
    return this.getSetting$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DeviceSetting>>) => r.body)
    );
  }
  getSetting$Response(params: {
    deviceId: number;
  }): Observable<StrictHttpResponse<Array<DeviceSetting>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DeviceService.postSettingPath,
      'get'
    );
    if (params) {
      rb.path('deviceId', params.deviceId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DeviceSetting>>;
        })
      );
  }
}
