<div class="flex flex-col w-full">
    <div id="drag-container" class="relative">
        <img
                #imgElement
                id="imgElement"
                class="w-auto h-auto max-w-full max-h-full mx-auto"
                src="{{imgSrc}}"
                (load)="getAOI()"
                alt=""
              />
              
            <div id="aoi" class="absolute w-10 h-10 bg-transparent border-white  border-2 top-0 left-0">
            </div>
            <!-- <div id="testBox" class="absolute w-10 h-10 bg-red-600 top-0 left-0 opacity-20"></div> -->
    </div>
</div>
<div class="flex w-full items-center p-4 justify-center">
    <button mat-raised-button (click)="saveAOI()">Save</button>
</div>
