<form class="flex w-full">
  <mat-form-field class="flex w-full">
    <input
      type="text"
      placeholder="add Label"
      matInput
      [formControl]="labelControl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="addLabel()"
      [displayWith]="displayLabel"
    >
      <mat-option
        *ngFor="let option of filteredLabels | async"
        [value]="option"
      >
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <button
    mat-icon-button
    *ngIf="labelControl.value"
    type="submit"
    (click)="createLabel()"
    class="flex"
  >
    <mat-icon>add</mat-icon>
  </button>
</form>
