import { Component, OnInit } from '@angular/core';
import { MapMouseEvent } from 'mapbox-gl';
import { LocationService } from 'src/app/location.service';

@Component({
  selector: 'app-locator',
  templateUrl: './locator.component.html',
  styleUrls: ['./locator.component.scss'],
})
export class LocatorComponent implements OnInit {
  coordinates: [number, number] = [30.5595, -22.9375];
  layerPaint = {
    'circle-radius': 7,
    'circle-color': '#1cc732',
  };
  findMe(): void {
    this.locator.getPosition().then((data) => {
      this.coordinates = [data.lng, data.lat];
    });
  }

  constructor(private locator: LocationService) {}

  ngOnInit(): void {
    this.findMe();
  }
  onDragEnd = (event: MapMouseEvent) => {
    let newCoords = JSON.stringify([event.lngLat.lng, event.lngLat.lat]);
    // this.saveSetting({ name: 'location', value: newCoords });
    this.coordinates = [event.lngLat.lng, event.lngLat.lat];
  };
  changeColor(color: string) {
    this.layerPaint = { ...this.layerPaint, 'circle-color': color };
  }
}
