import { NodeWithI18n } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Device, DeviceRegistrationInfo, Spaia_Event } from '../api/models';
import { DeviceService, EventsService } from '../api/services';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {
  eventInfo: Spaia_Event[] = [
    {
      regions: [{ x: 0, y: 51, w: 590, h: 429, labels: [] }],
      time: '2021-09-18T17:14:39Z',
      media: [],
      deviceId: 1,
      type: 'activity',
      location: [-26.13181173334509, 28.018850709271913],
      sensorData: [
        { type: 'temperature', sensorId: 1, value: 25.890234375 },
        { type: 'humidity', sensorId: 1, value: 24.223788457823133 },
        { type: 'pressure', sensorId: 1, value: 849.9203183842598 },
      ],
    },
  ];
  devices?: Device[] = [];

  constructor(
    private deviceService: DeviceService,
    private eventService: EventsService
  ) {}

  ngOnInit(): void {}
  generateEvent() {
    this.eventService.setEvents({ body: this.eventInfo }).then((d) => {
      if (d) {
        d.subscribe((x) => console.log(x));
      }
    });
  }
  createDevice() {}
  getDevices() {
    this.deviceService
      .getDevices()
      .subscribe((data) => (this.devices = data.devices));
  }
  registerDevice() {
    const registrationInfo: DeviceRegistrationInfo = {
      name: 'demo-ResearchAssistant',
      notes: 'tesing register',
    };

    this.deviceService
      .registerDevice({ body: registrationInfo })
      .subscribe((data) => console.log(data));
  }
  getEvents(deviceId: number) {
    this.eventService
      .getEvents({ deviceIds: [deviceId, 2], limit: 1 })
      .subscribe((data) => {
        console.log(data);
      });
  }
}
