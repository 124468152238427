<mat-card class="mapBox" class="flex w-full h-full">
  <mat-card-content class="flex w-full h-full">
    <mgl-map
      [style]="'mapbox://styles/notsosapien/cii1z90hg00iqiolzlotxab0a'"
      [zoom]="[14]"
      [center]="coordinates"
      class="flex w-full h-full"
    >
      <mgl-geojson-source id="point">
        <mgl-feature
          [properties]=""
          [geometry]="{
            type: 'Point',
            coordinates: coordinates
          }"
          [mglDraggable]="targetLayer"
          (featureDragEnd)="onDragEnd($event)"
        ></mgl-feature>
      </mgl-geojson-source>
      <mgl-layer
        #targetLayer
        class="marker"
        id="point3"
        type="circle"
        source="point"
        [paint]="layerPaint"
        (layerMouseEnter)="changeColor('#1dde36')"
        (layerMouseLeave)="changeColor('#1cc732')"
      ></mgl-layer>
    </mgl-map>
  </mat-card-content>
</mat-card>
