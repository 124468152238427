<div class="p-4">
  <h1 mat-dialog-title>Confirm Action</h1>
  <div mat-dialog-content>
    <p>Caution: This event has labels. Are you sure you want to delete it?</p>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-button color="warn" [mat-dialog-close]="true">Delete</button>
  </div>
</div>
